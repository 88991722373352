import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  Button,
  Typography,
  TextField,
  Avatar,
  List,
  ListItem,
  FormGroup,
  Checkbox,
  ListItemText,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput,
  InputLabel,
  Autocomplete,
} from "@mui/material";
import callIcon from "@assets/images/call.svg";
import speechIcon from "@assets/images/speech-bubble.svg";
import searchIcon from "@assets/images/magnifying-glass.svg";
// import editvariantIcon from "@assets/images/edit-variant.svg";
import Header from "@components/header";
import Footer from "@components/footer";
import API from "@services/axios";
import { useStore } from "@store/store";
import { schema } from "@utils/schemas";
import validationSchema from "@utils/validationSchemas";
import _ from "lodash";
import {
  FETCH_CUSTOMERS,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_FAILURE,
  FETCH_BRANDS,
  FETCH_BRANDS_SUCCESS,
  FETCH_BRANDS_FAILURE,
  FETCH_BRAND_CATEGORIES,
  FETCH_BRAND_CATEGORIES_SUCCESS,
  FETCH_BRAND_CATEGORIES_FAILURE,
  EDIT_CUSTOMER,
  EDIT_CUSTOMER_SUCCESS,
  EDIT_CUSTOMER_FAILURE,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAILURE,
} from "@utils/actionType";
import BrandFilterIcon from "@assets/images/brandFilter.svg";
import ViewCustomer from "./view-customer-popup";
import { customerListStyle } from "./style";
import { useDebounce } from "@hooks/useDebounce";
import { countries, AnnualTurnoverDataSelect } from "@utils/commonData";
import deleteIcon from "@assets/images/delete.png";
import Loader from "@components/loader";
import { handleColorClass } from "@utils/commonFunctions";
import CustomPagination from "@components/pagination";
import { toast } from "react-toastify";
import ConfirmationPopup from "@components/confirmationPopup";
import { getRole } from "@utils/commonFunctions";
import { userRole } from "@utils/constant";
import EditCustomer from "./edit-customer-popup";
import { useTheme } from "@mui/material/styles";
import ReactSelect from "react-select";
import { pascalCase } from "pascal-case";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function CustomerList() {
  const theme = useTheme();
  const classes = customerListStyle();
  let navigate = useNavigate();
  const [state, dispatch] = useStore();
  const [customer, setCustomer] = useState(null);
  const [openView, setOpenView] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [, setScroll] = useState("paper");
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [initialValue, setInitialValue] = useState(schema.customerSchema);
  const [openBrandAccess, setOpenBrandAccess] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [categoryName, setCategoryName] = useState([]);
  const [openConfirmPopup, setConfirmPopup] = useState(false);
  const [confirmParams, setConfirmParams] = useState(null);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [filterCustomer, setFilterCustomer] = useState(1);
  const [filterCustomerText, setFilterCustomerText] = useState("All");
  const [annualTurnover, setAnnualTurnver] = useState("");
  const [countriesData, setCountriesData] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([""]);
  const [regionsData, setRegionsData] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [annualData, setAnnualData] = useState(AnnualTurnoverDataSelect);
  const [filtersApplied, setIsFiltersApplied] = useState({
    country: false,
    region: false,
    annualTurnover: false,
  });
  const [countryFilter, setCountryFilter] = useState([]);
  const [countryFilterCounter, setCountryFilterCounter] = useState("Country");
  const [regionFilter, setRegionFilter] = useState([]);
  const [regionFilterCounter, setRegionFilterCounter] = useState("Region");
  const [annualTurnoverFilter, setAnnualTurnoverFilter] = useState("");
  const [annualTurnoverFilterCounter, setAnnualTurnoverFilterCounter] =
    useState("Annual Turnover");
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    window.scrollTo(0, 0);
  };

  const filterClient = () => {
    if (search.trimStart() === "") {
      getCustomers();
    } else {
      handleFilterStatus();
    }
  };

  useDebounce(() => filterClient(), 1000, search);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const getCustomers = () => {
    dispatch({ type: FETCH_CUSTOMERS });
    API.get(`/client?page=${page}`)
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: FETCH_CUSTOMERS_SUCCESS,
            payload: response.data.data.data,
          });
          if (!response.data.data.data) {
            setData(response.data.data);
          } else {
            setData(response.data.data.data);
          }
          setCount(response?.data?.totalPageCount);
          if (state?.customers?.customerId) {
            let cust = response.data.data.data.filter(
              (cu) => cu.id === +state?.customers?.customerId
            );
            handleCustomer(...cust);
          }
        } else {
          dispatch({
            type: FETCH_CUSTOMERS_FAILURE,
            payload: response.data.data,
          });
          toast.error(response.data.errorMessage);
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_CUSTOMERS_FAILURE, payload: error });
      });
  };

  const handleFilterStatus = () => {
    dispatch({ type: FETCH_CUSTOMERS });
    API.post(`/client/search?page=${page}&search=${search}`, {
      countries: countryFilter.map((item) => item.country),
      sales_regions: regionFilter.map((item) => item.country),
      annual_turnover: annualTurnoverFilter,
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: FETCH_CUSTOMERS_SUCCESS,
            payload: response.data.data,
          });

          setData(response.data.data);
          setCount(response?.data?.totalPageCount);
          if (state?.customers?.customerId) {
            let cust = response.data.data.data.filter(
              (cu) => cu.id === +state?.customers?.customerId
            );
            handleCustomer(...cust);
          }
        } else {
          dispatch({ type: FETCH_CUSTOMERS_FAILURE });
          toast.error(response.data.errorMessage);
        }
      })
      .catch((err) => {
        dispatch({ type: FETCH_CUSTOMERS_FAILURE });
        toast.error("Some thing went wrong");
      });
  };

  useEffect(() => {
    search === "" ? getCustomers() : handleFilterStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    handleFilterStatus();
  }, [search, countryFilter, regionFilter, annualTurnoverFilter]);

  const handleInvite = (id) => {
    API.get(`client/${id}/invite`)
      .then(() => {
        getCustomers();
        handleViewCustomerClose();
        toast.success("Client Invited Successfully");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const editCustomer = (data) => {
    if (data.roleInCompany) {
      data.role_in_company = data.roleInCompany;
      delete data.roleInCompany;
    }

    if (data.annualTurnover) {
      data.annual_turnover = data.annualTurnover;
      delete data.annualTurnover;
    }

    if (data.totalEmployees) {
      data.no_of_employees = data.totalEmployees;
      delete data.totalEmployees;
    }

    if (data.primaryMethodOfSale) {
      data.primary_method_of_sale = data.primaryMethodOfSale;
      delete data.primaryMethodOfSale;
    }

    if (data.regionsToSell) {
      data.regions_to_sell = data.regionsToSell;
      delete data.regionsToSell;
    }

    if (data.brandsCurrentlyWorking) {
      data.brands_currently_working = data.brandsCurrentlyWorking;
      delete data.brandsCurrentlyWorking;
    }

    if (data.facebookURL) {
      data.facebook_url = data.facebookURL;
      delete data.facebookURL;
    }

    if (data.instagramURL) {
      data.instagram_url = data.instagramURL;
      delete data.instagramURL;
    }

    if (data.linkedinURL) {
      data.linkedin_url = data.linkedinURL;
      delete data.linkedinURL;
    }

    if (data.companyWebsiteURL) {
      data.other_social_1 = data.companyWebsiteURL;
      delete data.companyWebsiteURL;
    }

    if (data.supplierName) {
      data.supplier_name = data.supplierName;
      delete data.supplierName;
    }

    if (data.otherNotes) {
      data.other_notes = data.otherNotes;
      delete data.otherNotes;
    }

    dispatch({ type: EDIT_CUSTOMER });
    API.post(`client/${customer.id}`, data)
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: EDIT_CUSTOMER_SUCCESS,
            payload: response.data.data,
          });
          getCustomers();
          handleViewCustomerClose();
          handleEditCustomerClose();
          toast.success("Edit customer successfully");
        } else {
          alert("failure");
          dispatch({
            type: EDIT_CUSTOMER_FAILURE,
            payload: response.data.data,
          });
          toast.error(response.data.errorMessage);
        }
      })
      .catch((err) => {
        dispatch({ type: EDIT_CUSTOMER_FAILURE, payload: err });
      });
  };
  const handleDeleteCustomer = (id) => {
    dispatch({ type: DELETE_CUSTOMER });
    API.delete(`staff/delete-client/${id}`)
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: DELETE_CUSTOMER_SUCCESS,
          });
          toast.success("Client deleted successfully");
          getCustomers();
        } else {
          dispatch({ type: DELETE_CUSTOMER_FAILURE });
          toast.error(response.data.errorMessage);
        }
      })
      .catch((err) => {
        dispatch({ type: DELETE_CUSTOMER_FAILURE });
      });
    closeConfirmPopup();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: validationSchema.editCustomerValidationSchema,
    onSubmit: (value) => {
      value = { ...value, phone: value.phone.replace(/[\s-)(]+/g, "") };
      value?.brand_ids?.length
        ? editCustomer(value)
        : toast.error("At least one brand is required");

      handleFilterStatus();
    },
  });

  const handleViewCustomerClose = () => {
    setCustomer();
    setOpenView(false);
  };

  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

  const modifyRegionsToSell = () => {
    const temp = [];

    if (customer?.regions_to_sell.length) {
      for (let region of customer?.regions_to_sell) {
        for (let index = 1; index < region.length; index++) {
          if (region[index] === region[index].toUpperCase()) {
            temp.push(pascalCase(region));
            break;
          } else {
            temp.push(region);
            break;
          }
        }
      }

      // const unique = temp.filter(onlyUnique);

      return temp;
    }
    return [];
  };

  const handleEditCustomerOpen = (scrollType) => () => {
    setInitialValue({
      userName: customer?.user_name,
      firstName: customer?.first_name,
      lastName: customer?.last_name,
      company: customer?.company,
      roleInCompany: customer?.role_in_company,
      totalEmployees: customer?.no_of_employees,
      streetAddress: customer?.street_address,
      city: customer?.city,
      postalCode: customer?.postal_code,
      country: customer?.country,
      phone: customer?.phone,
      emailAddress: customer?.email,
      primaryMethodOfSale: customer?.primary_method_of_sale,
      regionsToSell: modifyRegionsToSell(),
      annualTurnover: customer?.annual_turnover,
      brandsCurrentlyWorking: customer?.brands_currently_working,
      facebookURL: customer?.facebook_url,
      instagramURL: customer?.instagram_url,
      linkedinURL: customer?.linkedin_url,
      companyWebsiteURL: customer?.other_social_1,
      source: customer?.source,
      supplierName: customer?.supplier_name,
      otherNotes: customer?.other_notes,
      brand_ids: customer?.suppliers?.length
        ? customer?.suppliers?.map((item) => item.id)
        : [],
    });
    setOpenEdit(true);
    setScroll(scrollType);
  };
  const handleEditCustomerClose = () => {
    setOpenEdit(false);
    formik.handleReset();
    setInitialValue(schema.customerSchema);
  };

  const handleCustomer = (cust) => {
    setCustomer(cust);
    setOpenView(true);
  };

  const getInitialBrands = () => {
    const initialBrands = customer?.suppliers?.length
      ? customer?.suppliers?.map((item) => item.id)
      : [];
    setSelectedBrands(initialBrands);
  };
  useEffect(() => {
    getInitialBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const getBrands = () => {
    dispatch({ type: FETCH_BRANDS });
    API.get("/brand")
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: FETCH_BRANDS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          dispatch({ type: FETCH_BRANDS_FAILURE, payload: response.data.data });
          toast.error(response.data.errorMessage);
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_BRANDS_FAILURE, payload: error });
      });
  };

  const getCategories = () => {
    dispatch({ type: FETCH_BRAND_CATEGORIES });
    API.get("/category")
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: FETCH_BRAND_CATEGORIES_SUCCESS,
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: FETCH_BRAND_CATEGORIES_FAILURE,
            payload: response.data.data,
          });
          toast.error(response.data.errorMessage);
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_BRAND_CATEGORIES_FAILURE, payload: error });
      });
  };

  const getBrandsByCategory = (data) => {
    dispatch({ type: FETCH_BRANDS });
    API.post("/brand/search-brands/category", { categories: data })
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: FETCH_BRANDS_SUCCESS,
            payload:
              typeof response.data.data === "string" ? [] : response.data.data,
          });
        } else {
          dispatch({ type: FETCH_BRANDS_FAILURE, payload: response.data.data });
          toast.error(response.data.errorMessage);
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_BRANDS_FAILURE, payload: error });
      });
  };

  const populateCountries = () => {
    const populatedCountries = [];

    for (let item of countries) {
      populatedCountries.push({
        value: item.label,
        label: item.label,
      });
    }

    setCountriesData(populatedCountries);
  };

  const populateRegions = () => {
    const populatedRegions = [];

    for (let item of countries) {
      populatedRegions.push({
        value: item.label,
        label: item.label,
      });
    }

    setRegionsData(populatedRegions);
  };

  useEffect(() => {
    getBrands();
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategoryName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    value.length ? getBrandsByCategory(value) : getBrands();
  };

  const handleBrandAccessOpen = () => {
    setOpenBrandAccess(true);
    setSelectedBrands(formik?.values?.brand_ids);
  };
  const handleBrandAccessClose = () => {
    setSelectedBrands(formik?.values?.brand_ids);
    setOpenBrandAccess(false);
  };
  const handleBrandAccessChange = (id) => {
    let value = [...selectedBrands, id];
    if (selectedBrands.includes(id)) {
      value = value.filter((f) => f !== id);
    }
    setSelectedBrands(value);
  };
  const handleBrandAccessSubmit = (data) => {
    setSelectedBrands(selectedBrands);
    formik.setFieldValue("brand_ids", [...selectedBrands]);
    handleBrandAccessClose();
  };

  const handleOpenConfirmPopup = (id) => {
    setConfirmPopup(true);
    setConfirmParams({ id });
  };
  const closeConfirmPopup = () => {
    setConfirmPopup(false);
    setConfirmParams(null);
  };

  const getCountries = () => {
    dispatch({ type: FETCH_BRANDS });
    API.get("/countries")
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: FETCH_BRANDS_SUCCESS,
            payload: response.data.data,
          });

          setCountriesData(response.data.data);
        } else {
          dispatch({ type: FETCH_BRANDS_FAILURE, payload: response.data.data });
          toast.error(response.data.errorMessage);
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_BRANDS_FAILURE, payload: error });
      });
  };

  const filterAndSearch = data?.filter((i) => {
    if (filterCustomerText === "All") {
      return i;
    } else if (filterCustomerText === "Country") {
      // const temp = [];

      // for (let item of countries) {
      //   temp.push(pascalCase(item));
      // }

      if (selectedCountries.includes(i?.country)) {
        return i;
      }
    }
    // } else if (filterCustomerText === "Sale Region") {
    //   if (_.intersection(selectedCountries, i?.regions_to_sell).length) {
    //     return i;
    //   }
    // } else if (filterCustomerText === "Annual Turnover") {
    //   if (i?.annual_turnover === annualTurnover) {
    //     return i;
    //   }
    // }
  });

  return (
    <div className={classes.customerListWrapper}>
      <Header />
      <Loader
        loading={
          state?.brands?.loadingBrands ||
          state?.customers?.loadingCustomer ||
          state?.customers?.editingCustomer ||
          state?.customers?.deletingCustomer
        }
      />
      <div className="customer-list-page-wrapper">
        <div className="container">
          <div className="customer-list-heading">
            <Typography variant="h1">Customer List</Typography>
            <div className="customer-search-wrapper">
              <div className="form-wrapper">
                <div className="form-group">
                  <TextField
                    id="search"
                    type="search"
                    variant="outlined"
                    placeholder="SEARCH"
                    value={search}
                    onChange={handleSearch}
                  />
                  <img src={searchIcon} alt="search icon" />
                </div>
              </div>
              {!(getRole() === userRole.crm) && (
                <Button
                  color="primary"
                  className="primary-btn"
                  onClick={() => navigate("/add-new-customer")}
                >
                  Add New Customer
                </Button>
              )}
            </div>
          </div>

          <FormControl
            sx={{
              m: 1,
              // width: 219,
              "@media(max-width:500px)": {
                width: "auto",
              },
            }}
          >
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={countries}
              disableCloseOnSelect
              getOptionLabel={(option) => option.country}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.country}
                </li>
              )}
              // style={{ width: 500 }}
              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <input
                    style={{
                      cursor: "pointer",
                      width: 150,
                      height: 55,
                      border: "1px solid",
                      borderColor: filtersApplied.country
                        ? "#9D0F1D"
                        : "#E5E4E8",
                      borderRadius: 8,
                      textAlign: "center",
                      width: 363,
                    }}
                    placeholder={countryFilterCounter}
                    type="text"
                    {...params.inputProps}
                  />
                </div>
              )}
              onChange={(event, newValue) => {
                setCountryFilter(newValue);
                setCountryFilterCounter(`Selected ${newValue.length}`);

                if (newValue.length) {
                  setIsFiltersApplied({ ...filtersApplied, country: true });
                } else {
                  setCountryFilterCounter("Country");
                  setIsFiltersApplied({
                    ...filtersApplied,
                    country: false,
                  });
                }
              }}
            />
          </FormControl>

          <FormControl
            sx={{
              m: 1,
              // width: 219,
              "@media(max-width:500px)": {
                width: "auto",
              },
            }}
          >
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={countries}
              disableCloseOnSelect
              getOptionLabel={(option) => option.country}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.country}
                </li>
              )}
              // style={{ width: 500 }}
              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <input
                    style={{
                      cursor: "pointer",
                      width: 150,
                      height: 55,
                      border: "1px solid",
                      borderColor: filtersApplied.region
                        ? "#9D0F1D"
                        : "#E5E4E8",
                      borderRadius: 8,
                      textAlign: "center",
                      width: 363,
                    }}
                    placeholder={regionFilterCounter}
                    type="text"
                    {...params.inputProps}
                  />
                </div>
              )}
              onChange={(event, newValue) => {
                setRegionFilter(newValue);
                setRegionFilterCounter(`Selected ${newValue.length}`);

                if (newValue.length) {
                  setIsFiltersApplied({ ...filtersApplied, region: true });
                } else {
                  setRegionFilterCounter("Region");
                  setIsFiltersApplied({ ...filtersApplied, region: false });
                }
              }}
            />
          </FormControl>

          <FormControl
            sx={{
              m: 1,
              // width: 219,
              "@media(max-width:500px)": {
                width: "auto",
              },
            }}
          >
            <Autocomplete
              autoWidth
              id="checkboxes-tags-demo"
              options={annualData}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              // style={{ width: 500 }}
              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <input
                    style={{
                      cursor: "pointer",
                      width: 150,
                      height: 55,
                      border: "1px solid",
                      borderColor: filtersApplied.annualTurnover
                        ? "#9D0F1D"
                        : "#E5E4E8",
                      borderRadius: 8,
                      textAlign: "center",
                      width: 363,
                    }}
                    placeholder={annualTurnoverFilterCounter}
                    type="text"
                    {...params.inputProps}
                  />
                </div>
              )}
              onChange={(event, newValue) => {
                if (newValue) {
                  if (newValue === "All") {
                    setAnnualTurnoverFilter("");
                  } else {
                    setAnnualTurnoverFilter(newValue);
                  }

                  setIsFiltersApplied({
                    ...filtersApplied,
                    annualTurnover: true,
                  });
                } else {
                  setAnnualTurnoverFilterCounter("Annual Turnover");
                  setIsFiltersApplied({
                    ...filtersApplied,
                    annualTurnover: true,
                  });
                }
              }}
            />
          </FormControl>

          <div className="white-box customer-list-box">
            {filterAndSearch?.length ? (
              filterAndSearch?.map((customer, index) => (
                <div
                  className={`customer-list-row ${handleColorClass(index + 1)}`}
                  key={customer.id}
                >
                  <div
                    className="left-column"
                    onClick={() => handleCustomer(customer)}
                  >
                    {customer?.avatar ? (
                      <Avatar
                        className="short-name"
                        alt={customer?.avatar}
                        src={customer?.avatar}
                      />
                    ) : (
                      <Avatar className="short-name">
                        {customer.first_name ? customer.first_name[0] : null}
                        {customer.last_name ? customer.last_name[0] : null}
                      </Avatar>
                    )}
                    <div className="customer-detail">
                      <span className="customer-name">
                        {customer.first_name} {customer.last_name}
                      </span>
                      <Link to="" title="Mail us">
                        {customer.email}
                      </Link>
                      {/* <a
                          href={`mailto:${customer?.email}`}
                          title="Chat us"
                          target="_new"
                        >
                          {customer?.email}
                        </a> */}
                    </div>
                  </div>
                  <div className="right-column">
                    <List className="contact-list-wrapper">
                      <ListItem>
                        <a
                          href={`tel:${customer?.phone}`}
                          title="Call us"
                          className="red-circle-icon"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={callIcon} alt="call icon" />
                        </a>
                      </ListItem>
                      <ListItem>
                        <a
                          href={`mailto:${customer?.email}`}
                          title="Chat us"
                          className="red-circle-icon"
                          target="_new"
                        >
                          <img src={speechIcon} alt="speech icon" />
                        </a>
                      </ListItem>
                      {/* <ListItem>
                        <Link
                          to=""
                          title="Delete user"
                          onClick={() => handleOpenConfirmPopup(customer.id)}
                        >
                          <img src={deleteIcon} alt="delete icon" />
                        </Link>
                      </ListItem> */}
                    </List>
                  </div>
                </div>
              ))
            ) : (
              <p>No customer found</p>
            )}

            <ViewCustomer
              customer={customer && customer}
              handleInvite={handleInvite}
              handleEditCustomerOpen={handleEditCustomerOpen("body")}
              handleEditCustomerClose={handleEditCustomerClose}
              openEdit={openEdit}
              openView={openView}
              handleViewCustomerClose={() => handleViewCustomerClose()}
              formik={formik}
              brands={state?.brands?.brandsData}
              selectedBrands={selectedBrands}
              openBrandAccess={openBrandAccess}
              handleBrandAccessOpen={handleBrandAccessOpen}
              handleBrandAccessClose={handleBrandAccessClose}
              handleBrandAccessChange={handleBrandAccessChange}
              handleBrandAccessSubmit={handleBrandAccessSubmit}
              categories={state?.brands?.categoriesData}
              categoryName={categoryName}
              handleCategoryChange={handleCategoryChange}
              customDetail={true}
              isLead={false}
            />
            <EditCustomer
              customer={customer && customer}
              open={openEdit}
              handleClose={handleEditCustomerClose}
              formik={formik}
              brands={state?.brands?.brandsData}
              selectedBrands={selectedBrands}
              openBrandAccess={openBrandAccess}
              handleBrandAccessOpen={handleBrandAccessOpen}
              handleBrandAccessClose={handleBrandAccessClose}
              handleBrandAccessChange={handleBrandAccessChange}
              handleBrandAccessSubmit={handleBrandAccessSubmit}
              categories={state?.brands?.categoriesData}
              categoryName={categoryName}
              handleCategoryChange={handleCategoryChange}
            />
          </div>
          {!!data?.length && (
            <CustomPagination
              count={count}
              page={page}
              onChange={handleChangePage}
            />
          )}
        </div>
        <ConfirmationPopup
          open={openConfirmPopup}
          handleClose={closeConfirmPopup}
          handleConfirm={() => handleDeleteCustomer(confirmParams?.id)}
          confirmText={"Are you sure to delete client?"}
        />
      </div>
      <Footer />
    </div>
  );
}

export default CustomerList;
