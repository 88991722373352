import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Typography, Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useStore } from "@store/store";
import validationSchema from "@utils/validationSchemas";
import { DELETE_ACCOUNT_STEPS, LOGOUT } from "@utils/actionType";
import API from "@services/axios";
import ModalBox from "@components/ModalBox";
import { accountDeletionSteps } from "@utils/constant";
import successIcon from "@assets/images/success-icon.png";

function DeleteUser() {
  const [state, dispatch] = useStore();
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { accountDeletionReason: "" },
    validationSchema: validationSchema.accountDeletionReasonValidationSchema,
    onSubmit: (value) => {
      if (value) {
        dispatch({
          type: DELETE_ACCOUNT_STEPS,
          payload: {
            step: accountDeletionSteps.AccountDeletionConfirmation,
            isLoading: false,
          },
        });
      }
    },
  });

  const handleClose = (e) => {
    dispatch({
      type: DELETE_ACCOUNT_STEPS,
      payload: {
        step: "",
        isLoading: false,
      },
    });
  };

  const handleComfirm = () => {
    let reason = formik.values.accountDeletionReason;
    dispatch({
      type: DELETE_ACCOUNT_STEPS,
      payload: {
        step: accountDeletionSteps.AccountDeletionConfirmation,
        isLoading: true,
      },
    });
    API.get(`/client/delete-user-account?reason=${reason}`)
      .then((response) => {
        if (response.data.message === "Account deleted successfully") {
          dispatch({
            type: DELETE_ACCOUNT_STEPS,
            payload: {
              step: accountDeletionSteps.accountDeletionSuccess,
              isLoading: false,
            },
          });

          setTimeout(() => {
            dispatch({
              type: DELETE_ACCOUNT_STEPS,
              payload: {
                step: "",
                isLoading: false,
              },
            });

            dispatch({ type: LOGOUT });
            navigate("/login");
          }, 2000);
        } else {
          dispatch({
            type: DELETE_ACCOUNT_STEPS,
            payload: {
              step: accountDeletionSteps.accountDeletionError,
              isLoading: false,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_ACCOUNT_STEPS,
          payload: {
            step: accountDeletionSteps.accountDeletionError,
            isLoading: false,
          },
        });
      });
  };

  return (
    <div>
      <ModalBox
        open={state?.profile?.step}
        handleClose={handleClose}
        title="Account Deletion"
        style={{ display: "flex", flexDirection: "column", gap: 2 }}
        confirmText={
          state.profile.step ===
          accountDeletionSteps.AccountDeletionConfirmation
            ? "Are you sure you want to delete your account ?"
            : ""
        }
        Children={
          state?.profile?.step ===
          accountDeletionSteps.AccountDeletionReasion ? (
            <>
              <TextField
                sx={{ width: "100%" }}
                name="accountDeletionReason"
                id="accountDeletionReason"
                label="Reason for Account deletion ?"
                type="text"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.accountDeletionReason}
                error={
                  formik.touched.accountDeletionReason &&
                  Boolean(formik.errors.accountDeletionReason)
                }
                helperText={
                  formik.touched.accountDeletionReason &&
                  formik.errors.accountDeletionReason
                }
              />

              <Button
                color="primary"
                className="primary-btn"
                onClick={formik.handleSubmit}
              >
                Submit
              </Button>
            </>
          ) : state?.profile?.step ===
            accountDeletionSteps.AccountDeletionConfirmation ? (
            <>
              <Button
                color="primary"
                className="primary-btn"
                onClick={handleComfirm}
                disabled={state.profile.isLoading}
              >
                Yes
              </Button>
              <Button
                color="primary"
                className="primary-btn"
                onClick={handleClose}
                disabled={state.profile.isLoading}
              >
                No
              </Button>
            </>
          ) : state?.profile?.step ===
            accountDeletionSteps.accountDeletionSuccess ? (
            <>
              <Typography color={"green"} variant="h5">
                Your account is deleted please contact to support team at
                <a href="/"> nazim@eatroutes.com</a>
              </Typography>

              <em className="success-icon-wrapper">
                <img src={successIcon} alt="Success Icon" />
              </em>
            </>
          ) : state?.profile?.step ===
            accountDeletionSteps.accountDeletionError ? (
            <>
              <Typography color="red" variant="h4">
                Unable to delete User
              </Typography>
            </>
          ) : (
            <></>
          )
        }
      ></ModalBox>
    </div>
  );
}

export default DeleteUser;
