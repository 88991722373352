import React from "react";
import { Dialog, DialogContentText } from "@mui/material";
import { Box, DialogActions, Link, Typography } from "@mui/material";
import crossIcon from "@assets/images/cross-mark-white.svg";
import { colors } from "@utils/constant";

import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function ModalBox({ handleClose, open, title, Children, confirmText, style }) {
  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
      >
        {title && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: "8px",
            }}
            style={{ backgroundColor: colors.primary }}
          >
            <Typography variant="h4" color={'white'}>{title}</Typography>
            <Link sx={{ cursor: "pointer" }} onClick={handleClose}>
              <img src={crossIcon} alt="cross Icon" />
            </Link>
          </Box>
        )}

        {confirmText && (
          <DialogContentText sx={{ p: "8px" }} id="alert-dialog-description">
            <Typography variant="h6">{confirmText}</Typography>
          </DialogContentText>
        )}

        <DialogActions sx={style}>{Children}</DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
export default ModalBox;
