const schema = {
  loginSchema: {
    username: "",
    password: "",
    role: "staff",
  },

  customerSchema: {
    userName: "",
    firstName: "",
    lastName: "",
    company: "",
    roleInCompany: "",
    totalEmployees: "",
    streetAddress: "",
    country: "",
    city: "",
    postalCode: "",
    phone: "",
    emailAddress: "",
    verifyEmail: "",
    primaryMethodOfSale: [],
    regionsToSell: [],
    annualTurnover: "",
    brandsCurrentlyWorking: "",
    facebookURL: "",
    instagramURL: "",
    linkedinURL: "",
    companyWebsiteURL: "",
    source: "",
    supplierName: "",
    otherNotes: "",
    password: "",
    invite: false,
    brand_ids: [],
  },

  leadSchema: {
    firstName: "",
    unvitInpt: "UNVETTED",
    source: "",
    annualRevenue: "",
    // topInpt:'Unvited',
    lastName: "",
    company: "",
    streetAddress: "",
    city: "",
    postalCode: "",
    country: "",
    phone: "",
    emailAddress: "",
    rating: "",
    employee: "",
    currentBrand: "",
    facebook: "",
    instagram: "",
    linkedin: "",
    otherSocial: "",
    // vitedInpt:"",
    // verifyEmail: "",
    // password: "",
    // invite: false,
    saleMethod: [],
    regionsToSell: [],
  },
  customerLeadSchema: {
    userName: "",
    firstName: "",
    lastName: "",
    company: "",
    streetAddress: "",
    city: "",
    postalCode: "",
    country: "",
    phone: "",
    emailAddress: "",
    // verifyEmail: "",
    password: "",
    employee: "",
    anualRevenue: "",
    currentBrand: "",
    facebook: "",
    instagram: "",
    linkedin: "",
    otherSocial: "",
    // invite: false,
    // brand_ids: [],
  },

  myProfileSchema: {
    firstName: "",
    lastName: "",
    streetAddress: "",
    phone: "",
    emailAddress: "",
    avtar: "",
  },

  changePasswordSchema: {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  },
  forgotPasswordSchema: {
    email: "",
  },

  addProductSchema: {
    brandId: null,
    name: "",
    headline: "",
    image: "",
    nutrition_facts: "",
    description: "",
    categories: [],
    products_gallery: [],
    ingredients: "",

    variationTypes: null,
    subVariations: [],
    measurement: null,

    productSelfLife: "",
    countryOfOrigin: "",
    currency: "",
    unitsPerCase: "",
    unitsPerMasterCase: "",
    unitsPerPallet: "",
    palletLength: "",
    palletWidth: "",
    palletHeight: "",

    imageUrl: "",
    nutrition_factsUrl: "",
  },

  addBrandSchema: {
    firstName: "",
    lastName: "",
    phone: "",
    emailAddress: "",
    password: "",
    avatar: "",

    companyName: "",
    companyAddress: "",
    brandName: "",
    brandWebsite: "",
    categories: [],
    image: "",

    bankName: "",
    bankAddress: "",
    bankCity: "",
    bankState: "",
    bankPostalCode: "",
    bankCoutry: "",
    beneficiaryName: "",
    beneficiaryAddress: "",
    beneficiaryCity: "",
    beneficiaryState: "",
    beneficiaryPostalCode: "",
    beneficiaryCoutry: "",
    accountNumber: "",
    routingNumber: "",
    swiftCode: "",

    catalogForm: "",
    orderForm: "",
    businessLicense: "",
    wirePaymentDetail: "",

    avatarUrl: "",
    imageUrl: "",
    catalogFormUrl: "",
    orderFormUrl: "",
    businessLicenseUrl: "",
    wirePaymentDetailUrl: "",
  },

  addSampleSchema: {
    address: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
    notes: "",
    request: "",
  },

  requestSampleApprovedSchema: {
    is_chargeable: null,
    charge_amount: "",
  },

  requestSampleShippedSchema: {
    tracking_url: "",
  },

  requestSampleRejectedSchema: {
    reason: "",
  },

  requestQuoteReadySchema: {
    pdf: "",
    notes: "",
  },

  requestQuoteStaffApprovedSchema: {
    id: null,
    sales_order: "",
    tracking_url: "",
  },

  requestQuoteRejectSchema: {
    reason: "",
  },
};

export { schema };
