const colors = {
  primary: "#bf1e2e",
  white: "#FFFFFF",
  black: "#000",
  lightText: "#838383",
  lightGray: "#e5e4e8",
  yellow: "#ffbe00",
  blue: "#0a2d71",
  lightRed: "#f8f8f8",
  grayText: "#1e2423",
  red: "#be1e2d",
  darkRed: "#9d0f1d",
  grayBg: "#f9f9f9",
  green: "#4bb543",
  brown: "#918143",
  orange: "#c45f2b",
  lightYellow: "#d8871f",
  skyBlue: "#00aff0",
  lightGreen: "#3b8e2d",
  lightGrayBg: "#e5e4e9",
};

const userRole = {
  client: "client",
  brand: "supplier",
  staff: "staff",
  vrm: "vrm",
  admin: "admin",
  crm: "crm",
};

const accountDeletionSteps = {
  AccountDeletionReasion: "account deletion reason",
  AccountDeletionConfirmation: "account deletion confirmation",
  accountDeletionSuccess: "accountDeletionSuccess",
  accountDeletionError: "accountDeletionError",
};
export { colors, userRole, accountDeletionSteps };
