export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT = "LOGOUT";

export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
export const FETCH_CUSTOMERS_FAILURE = "FETCH_CUSTOMERS_FAILURE";

export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAILURE = "ADD_CUSTOMER_FAILURE";

export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const EDIT_CUSTOMER_SUCCESS = "EDIT_CUSTOMER_SUCCESS";
export const EDIT_CUSTOMER_FAILURE = "EDIT_CUSTOMER_FAILURE";

export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAILURE = "DELETE_CUSTOMER_FAILURE";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const EDIT_PROFILE = "EDIT_PROFILE";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_FAILURE = "EDIT_PROFILE_FAILURE";

export const SET_PROFILE_IMAGE = "SET_PROFILE_IMAGE";
export const SET_PROFILE_IMAGE_SUCCESS = "SET_PROFILE_IMAGE_SUCCESS";
export const SET_PROFILE_IMAGE_FAILURE = "SET_PROFILE_IMAGE_FAILURE";

export const GET_CONTACT_DETAILS = "GET_CONTACT_DETAILS";
export const GET_CONTACT_DETAILS_SUCCESS = "GET_CONTACT_DETAILS_SUCCESS";
export const GET_CONTACT_DETAILS_FAILURE = "GET_CONTACT_DETAILS_FAILURE";

export const FETCH_BRANDS = "FETCH_BRANDS";
export const FETCH_BRANDS_SUCCESS = "FETCH_BRANDS_SUCCESS";
export const FETCH_BRANDS_FAILURE = "FETCH_BRANDS_FAILURE";

export const FETCH_PENDING_BRANDS = "FETCH_PENDING_BRANDS";
export const FETCH_PENDING_BRANDS_SUCCESS = "FETCH_PENDING_BRANDS_SUCCESS";
export const FETCH_PENDING_BRANDS_FAILURE = "FETCH_PENDING_BRANDS_FAILURE";

export const ADD_BRAND = "ADD_BRAND";
export const ADD_BRAND_SUCCESS = "ADD_BRAND_SUCCESS";
export const ADD_BRAND_FAILURE = "ADD_BRAND_FAILURE";

export const DELETE_BRAND = "DELETE_BRAND";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const DELETE_BRAND_FAILURE = "DELETE_BRAND_FAILURE";

export const FETCH_REGULATORY = "FETCH_REGULATORY";
export const FETCH_REGULATORY_SUCCESS = "FETCH_REGULATORY_SUCCESS";
export const FETCH_REGULATORY_FAILURE = "FETCH_REGULATORY_FAILURE";

export const SAVE_LOCAL_REGULATORY = "SAVE_LOCAL_REGULATORY";

export const FETCH_SUB_OPTIONS = "FETCH_SUB_OPTIONS";
export const FETCH_SUB_OPTIONS_SUCCESS = "FETCH_SUB_OPTIONS_SUCCESS";
export const FETCH_SUB_OPTIONS_FAILURE = "FETCH_SUB_OPTIONS_FAILURE";

export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";

export const FETCH_PRODUCT_DETAILS = "FETCH_PRODUCT_DETAILS";
export const FETCH_PRODUCT_DETAILS_SUCCESS = "FETCH_PRODUCT_DETAILS_SUCCESS";
export const FETCH_PRODUCT_DETAILS_FAILURE = "FETCH_PRODUCT_DETAILS_FAILURE";

export const ADD_CSV = "ADD_CSV";
export const ADD_CSV_SUCCESS = "ADD_CSV_SUCCESS";
export const ADD_CSV_FAILURE = "ADD_CSV_FAILURE";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";

export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_FAILURE = "EDIT_PRODUCT_FAILURE";

export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

export const EDIT_PRODUCT_IMAGE = "EDIT_PRODUCT_IMAGE";
export const EDIT_PRODUCT_IMAGE_SUCCESS = "EDIT_PRODUCT_IMAGE_SUCCESS";
export const EDIT_PRODUCT_IMAGE_FAILURE = "EDIT_PRODUCT_IMAGE_FAILURE";

export const ADD_PRODUCT_VARIATIONS = "ADD_PRODUCT_VARIATIONS";
export const ADD_PRODUCT_VARIATIONS_SUCCESS = "ADD_PRODUCT_VARIATIONS_SUCCESS";
export const ADD_PRODUCT_VARIATIONS_FAILURE = "ADD_PRODUCT_VARIATIONS_FAILURE";

export const ADD_PRODUCT_Gallery = "ADD_PRODUCT_Gallery";
export const ADD_PRODUCT_Gallery_SUCCESS = "ADD_PRODUCT_Gallery_SUCCESS";
export const ADD_PRODUCT_Gallery_FAILURE = "ADD_PRODUCT_Gallery_FAILURE";

export const ADD_VARIATIONS_OPTION = "ADD_VARIATIONS_OPTION";
export const ADD_VARIATIONS_OPTION_SUCCESS = "ADD_VARIATIONS_OPTION_SUCCESS";
export const ADD_VARIATIONS_OPTION_FAILURE = "ADD_VARIATIONS_OPTION_FAILURE";

export const FETCH_BRAND_CATEGORIES = "FETCH_BRAND_CATEGORIES";
export const FETCH_BRAND_CATEGORIES_SUCCESS = "FETCH_BRAND_CATEGORIES_SUCCESS";
export const FETCH_BRAND_CATEGORIES_FAILURE = "FETCH_BRAND_CATEGORIES_FAILURE";

export const FETCH_PRODUCT_CATEGORIES = "FETCH_PRODUCT_CATEGORIES";
export const FETCH_PRODUCT_CATEGORIES_SUCCESS =
  "FETCH_PRODUCT_CATEGORIES_SUCCESS";
export const FETCH_PRODUCT_CATEGORIES_FAILURE =
  "FETCH_PRODUCT_CATEGORIES_FAILURE";

export const CREATE_QUOTES = "CREATE_QUOTES";

export const CREATE_QUOTE_REQUEST = "CREATE_QUOTE_REQUEST";
export const CREATE_QUOTE_REQUEST_SUCCESS = "CREATE_QUOTE_REQUEST_SUCCESS";
export const CREATE_QUOTE_REQUEST_FAILURE = "CREATE_QUOTE_REQUEST_FAILURE";

export const CREATE_SAMPLE_REQUEST = "CREATE_SAMPLE_REQUEST";
export const CREATE_SAMPLE_REQUEST_SUCCESS = "CREATE_SAMPLE_REQUEST_SUCCESS";
export const CREATE_SAMPLE_REQUEST_FAILURE = "CREATE_SAMPLE_REQUEST_FAILURE";

export const FETCH_VIEW_QUOTE_REQUEST = "FETCH_VIEW_QUOTE_REQUEST";
export const FETCH_VIEW_QUOTE_REQUEST_SUCCESS =
  "FETCH_VIEW_QUOTE_REQUEST_SUCCESS";
export const FETCH_VIEW_QUOTE_REQUEST_FAILURE =
  "FETCH_VIEW_QUOTE_REQUEST_FAILURE";

export const FETCH_VIEW_SAMPLE_REQUEST = "FETCH_VIEW_SAMPLE_REQUEST";
export const FETCH_VIEW_SAMPLE_REQUEST_SUCCESS =
  "FETCH_VIEW_SAMPLE_REQUEST_SUCCESS";
export const FETCH_VIEW_SAMPLE_REQUEST_FAILURE =
  "FETCH_VIEW_SAMPLE_REQUEST_FAILURE";

export const FILTER_SAMPLE = "FILTER_SAMPLE";
export const FILTER_SAMPLE_SUCCESS = "FILTER_SAMPLE_SUCCESS";
export const FILTER_SAMPLE_FAILURE = "FILTER_SAMPLE_FAILURE";

export const FILTER_QUOTE = "FILTER_QUOTE";
export const FILTER_QUOTE_SUCCESS = "FILTER_QUOTE_SUCCESS";
export const FILTER_QUOTE_FAILURE = "FILTER_QUOTE_FAILURE";

export const FETCH_VIEW_ORDER_REQUEST = "FETCH_VIEW_ORDER_REQUEST";
export const FETCH_VIEW_ORDER_REQUEST_SUCCESS =
  "FETCH_VIEW_ORDER_REQUEST_SUCCESS";
export const FETCH_VIEW_ORDER_REQUEST_FAILURE =
  "FETCH_VIEW_ORDER_REQUEST_FAILURE";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const FETCH_PRODUCT_VARIATIONS = "FETCH_PRODUCT_VARIATIONS";
export const FETCH_PRODUCT_VARIATIONS_SUCCESS =
  "FETCH_PRODUCT_VARIATIONS_SUCCESS";
export const FETCH_PRODUCT_VARIATIONS_FAILURE =
  "FETCH_PRODUCT_VARIATIONS_FAILURE";

export const FETCH_SUBVARIATIONS = "FETCH_SUBVARIATIONS";
export const FETCH_SUBVARIATIONS_SUCCESS = "FETCH_SUBVARIATIONS_SUCCESS";
export const FETCH_SUBVARIATIONS_FAILURE = "FETCH_SUBVARIATIONS_FAILURE";

export const FETCH_SUBVARIATIONS_OPTIONS = "FETCH_SUBVARIATIONS_OPTIONS";
export const FETCH_SUBVARIATIONS_OPTIONS_SUCCESS =
  "FETCH_SUBVARIATIONS_OPTIONS_SUCCESS";
export const FETCH_SUBVARIATIONS_OPTIONS_FAILURE =
  "FETCH_SUBVARIATIONS_OPTIONS_FAILURE";

export const APPROVE_REJECT_QUOTE_SAMPLE = "APPROVE_REJECT_QUOTE_SAMPLE";
export const APPROVE_REJECT_QUOTE_SAMPLE_SUCCESS =
  "APPROVE_REJECT_QUOTE_SAMPLE_SUCCESS";
export const APPROVE_REJECT_QUOTE_SAMPLE_FAILURE =
  "APPROVE_REJECT_QUOTE_SAMPLE_FAILURE";

export const ADD_QUOTE_AND_SAMPLE = "ADD_QUOTE_AND_SAMPLE";
export const ADD_QUOTE_AND_SAMPLE_SUCCESS = "ADD_QUOTE_AND_SAMPLE_SUCCESS";
export const ADD_QUOTE_AND_SAMPLE_FAILURE = "ADD_QUOTE_AND_SAMPLE_FAILURE";

export const SELECT_ALL_SHARE_BRANDS = "SELECT_ALL_SHARE_BRANDS";
export const DELETE_ACCOUNT_STEPS = "DELETE_ACCOUNT_STEPS"
